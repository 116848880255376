import { config } from './config'
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
// import store from '../store/store'

export type RequestOptions<V> = AxiosRequestConfig & { data?: V; authorized?: boolean }

export const backend = async <Response, Request = any>(
  path: string,
  options?: RequestOptions<Request>,
) => {
  let token

  // if (options?.authorized) {
  //   token = store.getState().auth.token
  // }

  const { data }: AxiosResponse<Response> = await axios(path, {
    baseURL: config.publicRuntimeConfig.backendRoot,
    ...options,
    headers: {
      ...options?.headers,
      ...(!!token && { Authorization: `Bearer ${token}` }),
      'Bypass-Tunnel-Reminder': 'true',
    },
  })

  return data
}

export const humpclub = async <T, V = any>(path: string, options?: RequestOptions<V>) => {
  let token

  // if (options.authorized) {
  //   token = store.getState().auth.token
  // }

  const { data }: AxiosResponse<T> = await axios(path, {
    baseURL: config.publicRuntimeConfig.humpclubRoot,
    ...options,
    headers: {
      ...options?.headers,
      ...(!!token && { Authorization: `Bearer ${token}` }),
      'Bypass-Tunnel-Reminder': 'true', // required when using npx tunnel to skip page redirect confirmations
    },
  })

  return data
}

export const query = <T>(path: string, options?: AxiosRequestConfig) => backend<T>(path, options)

export const humpclubQuery = <T>(path: string, options?: AxiosRequestConfig) =>
  humpclub<T>(path, options)

export const mutation = <R>(path: string, options?: AxiosRequestConfig) => data =>
  backend<R>(path, {
    method: 'post',
    ...options,
    data,
  })

export const humpclubMutation = <R>(path: string, options?: AxiosRequestConfig) => data =>
  humpclub<R>(path, {
    method: 'post',
    ...options,
    data,
  })
